import { createSgDashboardPropsMapping, createPptExportPropsMapping } from '../common/SgDashboard';
import { widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';

import { LicenseManager } from 'ag-grid-enterprise';
import { Grid } from 'ag-grid-community';
import { generateArclabAgGrid } from '../arclab-ag-grid-generator/arclab-ag-grid-generator';
import { asStringInitializedEnum } from '../common/Sgwt';
import { AgGridTheme } from 'sg-dashboard-sdk';
import { Props } from '../arclab-ag-grid-generator/api';
//
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-062901}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Socit_Gnrale_S.A.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SG_Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SG_Dashboard}_need_to_be_licensed___{SG_Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_December_2025}____[v3]_[01]_MTc2NzEzOTIwMDAwMA==50c7a4ab8c318b2f6e88508f8b75f82c');

export const ArclabAgGrid = generateArclabAgGrid(Grid);

const tagName = 'arclab-ag-grid';
if (window.customElements && !window.customElements.get(tagName)) {
  widgetize(tagName, ArclabAgGrid, {
    ...createSgDashboardPropsMapping('output-event'),
    ...createPptExportPropsMapping(),
    query: WidgetPropsMapping.asObject(),
    withQuickFilter: WidgetPropsMapping.asObject(), // asObject() uses JSON.parse() which is ok for parsing "true"/"false" into boolean
    withExport: WidgetPropsMapping.asObject(),
    getArcLabPptExport: WidgetPropsMapping.asObject(),
    excelExportParams: WidgetPropsMapping.asObject(),
    agTheme: asStringInitializedEnum<Props, AgGridTheme>()
  });
}
