import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  GridOptions as GridOptionsV20,
  GridApi as GridApiV20,
  ExcelExportParams as ExcelExportParamsV20
} from 'ag-grid-community';
import {
  GridOptions as GridOptionsV25,
  GridApi as GridApiV25,
  ExcelExportParams as ExcelExportParamsV25
} from 'sg-dashboard-ag-grid-v25';
import {
  GridOptions as GridOptionsV26,
  GridApi as GridApiV26,
  ExcelExportParams as ExcelExportParamsV26
} from 'sg-dashboard-ag-grid-v26';
import { checkSgDashboardProps } from '../common/SgDashboard';
import { useAsyncControl } from '../common/AsyncControl';
import { useEmptyStates, ErrorMsg } from '../common/EmptyStates';
import { useWidgetConfiguration } from '@sg-widgets/react-core';
import { useLastSeqNum } from '../common/useLastSeqNum';
import { UserActionRequiredError, RequestAccessError } from '../common/Errors';
import { merge } from 'lodash';
import { formatNumber } from '../common/utils';
import { AgGridTheme, PptExport } from 'sg-dashboard-sdk';
import { Loading } from '../common/Loading';
import { Props } from './api';

export type GridOptions = GridOptionsV20 | GridOptionsV25 | GridOptionsV26;
export type ExcelExportParams = ExcelExportParamsV20 | ExcelExportParamsV25 | ExcelExportParamsV26;

interface HeaderProps {
  api: GridApiV20 | GridApiV25 | GridApiV26;
  withQuickFilter: boolean;
  withExport: boolean;
  excelExportParams?: ExcelExportParams;
}

export enum AgGridStyleVersion {
  V20 = 'ag-grid',
  LATEST = 'ag-grid-latest'
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function generateArclabAgGridHeader() {
  const Header: React.FC<HeaderProps> = ({ api, withQuickFilter, withExport, excelExportParams }) => {
    const [filter, setFilter] = useState('');
    useEffect(() => {
      api.setQuickFilter(filter);
    }, [api, filter]);
    return (
      <div
        className={`d-flex ${
          withQuickFilter ? 'justify-content-between' : 'justify-content-end'
        } align-items-center flex-wrap`}
      >
        {withQuickFilter && (
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Quick search"
              value={filter}
              onChange={({ target: { value } }) => setFilter(value)}
            />
          </div>
        )}
        {withExport && (
          <div className="form-group">
            <button
              className="btn btn-flat-secondary btn-icon-text"
              onClick={() => {
                (api as any).exportDataAsExcel(excelExportParams);
              }}
            >
              <i className="icon">save_alt</i> Export
            </button>
          </div>
        )}
      </div>
    );
  };
  return Header;
}

// https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap-v4/blob/develop/%40sg-bootstrap/ag-grid-latest/src/index.ts
const agThemeSgBootstrap = {
  headerHeight: 56,
  rowHeight: 48
};

const agThemeSgBootstrapCondensed = {
  headerHeight: 32,
  rowHeight: 24
};

// any: justification => As we don't know exactly the type of the Grid object, we use any.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function generateArclabAgGrid(
  genericGrid: any,
  agGridStyleVersion: AgGridStyleVersion = AgGridStyleVersion.V20
) {
  const Header = generateArclabAgGridHeader();
  const ArclabAgGrid: React.FC<Props> = props => {
    const {
      theme,
      query,
      logKey,
      inputData,
      sgConnectEnvironment,
      withQuickFilter = false,
      withExport = false,
      getArcLabPptExport,
      setPptExport,
      excelExportParams,
      widgetAttributes,
      widgetProperties,
      agTheme
    } = props;
    checkSgDashboardProps(props, 'ArclabAgGrid');
    if (query === undefined) throw new Error(`ArclabAgGrid key[${logKey}] missing property[query]`);
    //
    const ref = useRef<HTMLDivElement | null>(null);
    const [options, setOptions] = useState<GridOptions | null>(null);
    const [ready, setReady] = useState(false);
    const { start: asyncStart, stop: asyncStop, cleanup } = useAsyncControl(logKey);
    const {
      start: emptyStatesStart,
      stop: emptyStatesStop,
      loading,
      errorMsg,
      userActionNeeded,
      param,
      requestAccessNeeded
    } = useEmptyStates();
    const conf = useWidgetConfiguration();
    const [api, setApi] = useState<GridApiV20 | GridApiV25 | GridApiV26 | null>(null);
    const { triggerEvent, userSelectionRef } = useLastSeqNum(props);

    const createOptions = useCallback(() => {
      let options: GridOptions = {
        suppressBrowserResizeObserver: true,
        // defaults to condensed
        ...agThemeSgBootstrapCondensed
      };
      if (agTheme === AgGridTheme.default) {
        options = { ...options, ...agThemeSgBootstrap };
      }
      return options;
    }, [agTheme]);

    useEffect(() => {
      async function createOpts(): Promise<void> {
        emptyStatesStart();
        const ctl = asyncStart();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { seqNum, ...inputs } = inputData;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let optionsAny: any = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let error: any = null;
        try {
          conf.debug(`createOpts() key[${logKey}] start`);
          optionsAny = await query({
            createOptions,
            widgetConfiguration: conf,
            logKey,
            inputData: inputs,
            UserActionRequiredError,
            RequestAccessError,
            merge,
            formatNumber,
            theme,
            sgConnectEnvironment,
            triggerEvent,
            userSelection: userSelectionRef.current,
            attributes: widgetAttributes,
            properties: widgetProperties
          });
          conf.debug(`createOpts() key[${logKey}] stop items[${JSON.stringify(optionsAny)}]`);
        } catch (err) {
          conf.error(`createOpts() key[${logKey}] stop error[${err}]`);
          error = err;
        }
        if (asyncStop(ctl)) return;
        if (emptyStatesStop(error)) {
          setOptions(null); // needed to call Grid.destroy()
          return;
        }
        //
        if (
          optionsAny === null || // typeof(null) returns 'object' (historical JS bug)
          typeof optionsAny !== 'object'
        ) {
          throw new Error(
            `ArclabAgGrid createOpts() key[${logKey}] options[${JSON.stringify(optionsAny)}] must be an object`
          );
        }
        // GridOptionsV20: justification: As V20 is the older version we can use this type to avoid typeScript type error and regression
        const options = optionsAny as GridOptionsV20;
        const { onGridReady: onGridReadyFromPrerequesiteJs } = options;
        // to be kept in this useEffect (to not mutate options once setOptions() is called)
        options.onGridReady = event => {
          const { api } = event;
          // columnApi.autoSizeAllColumns() must be called directly from the prerequisiteJs
          if (onGridReadyFromPrerequesiteJs) {
            onGridReadyFromPrerequesiteJs(event);
          }
          setApi(api);
        };
        setOptions(options);
      }
      createOpts();
      return cleanup;
    }, [
      query,
      cleanup,
      conf,
      inputData,
      logKey,
      asyncStart,
      asyncStop,
      emptyStatesStart,
      emptyStatesStop,
      theme,
      sgConnectEnvironment,
      triggerEvent,
      userSelectionRef,
      widgetProperties,
      widgetAttributes,
      createOptions
    ]);
    useEffect(() => {
      const { current: el } = ref;
      if (
        !el ||
        !options ||
        !ready // 'ready' is needed to prevent 'ResizeObserver loop limit exceeded' (e.g. when api.sizeColumnsToFit() is called)
      ) {
        return;
      }
      const grid = new genericGrid(el, options);
      return () => {
        grid.destroy();
      };
    }, [options, ready]);
    useEffect(() => {
      if (!getArcLabPptExport || !options || !setPptExport) return;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { seqNum, ...inputs } = inputData;
      const getPptExport: PptExport = () =>
        getArcLabPptExport({
          options,
          widgetConfiguration: conf,
          logKey,
          inputData: inputs,
          theme,
          sgConnectEnvironment
        });
      setPptExport(getPptExport);
    }, [getArcLabPptExport, setPptExport, options, conf, logKey, inputData, theme, sgConnectEnvironment]);
    //
    if (errorMsg)
      return (
        <ErrorMsg
          msg={errorMsg}
          param={param}
          userActionNeeded={userActionNeeded}
          requestAccessNeeded={requestAccessNeeded}
        />
      );
    if (loading) return <Loading />;
    return (
      <>
        <link
          href={`https://shared.sgmarkets.com/sg-bootstrap/v4/${agGridStyleVersion}/theme-${theme}.css`}
          onLoad={() => setReady(true)}
          rel="stylesheet"
        />
        <div className="h-100 w-100 d-flex flex-column">
          {api ? (
            <Header
              api={api}
              withQuickFilter={withQuickFilter}
              withExport={withExport}
              excelExportParams={excelExportParams}
            />
          ) : null}
          <div
            ref={ref}
            className={`ag-theme-sg-bootstrap ${agTheme !== AgGridTheme.default &&
              'ag-theme-sg-bootstrap-condensed'} flex-grow-1`}
          ></div>
        </div>
      </>
    );
  };
  return ArclabAgGrid;
}
